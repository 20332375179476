import React from 'react'
import LoginOTPpage from '../../Components/LoginOTPpage/LoginOTPpage'
import LoginWithEmail from '../../Components/LoginWithEmail/LoginWithEmail'
import LoginWithPhone from '../../Components/LoginWithPhone/LoginWithPhone'

function LoginPage() {
  return (
    <div>
      <LoginWithPhone/>
    </div>
  )
}

export default LoginPage
