import React from 'react'
import CategoryBannerimage from "../../../Assets/CategoryImage/Frame 1167.png"

function CategoryBanner() {
  return (
    <div>
        <div className=' sm:h-[409px] sm:mt-[24px] '>
      <img src={CategoryBannerimage} alt="" />

      </div>
    </div>
  )
}

export default CategoryBanner
